import { com } from '@eidu/entity'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { isEmpty } from 'lodash'
import getEntitiesBatch, { MAX_NUM_IDS_PER_BATCH } from '../api/entity/data/getEntitiesBatch'
import chunked from '../util/chunked'
import BatchCache from './BatchCache'
import deleteEntity from '../api/entity/data/deleteEntity'
import patchEntity from '../api/entity/data/patchEntity'
import postEntities from '../api/entity/data/postEntities'
import EqualityHashMap from '../util/EqualityHashMap'
import EntityWithLabelAndRelated from '../domain/entity/EntityWithLabelAndRelated'
import { requireNotUndefinedOrNull } from '../util/require'
import PageCache, { Page } from './PageCache'
import getEntitiesOfType from '../api/entity/data/getEntitiesOfType'
import getEntitiesFromResponses, { getEntitiesFromResponse } from '../domain/entity/getEntitiesFromResponses'
import patchEntities from '../api/entity/data/patchEntities'
import searchEntitiesOfType from '../api/entity/data/searchEntitiesOfType'
import AuthenticationContext, { createAuthContext } from '../api/authorization/AuthenticationContext'
import EntityToCreate = com.eidu.sharedlib.entity.api.entities.EntityToCreate
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import Patch from '../api/entity/data/Patch'

const BATCH_SIZE = MAX_NUM_IDS_PER_BATCH

let globalAuthContext: AuthenticationContext | undefined

export function setGlobalAuthContext(authContext: IAuthContext) {
  globalAuthContext = createAuthContext(authContext)
}

export const updateAccessToken = () => {
  const authContext = useContext<IAuthContext>(AuthContext)
  globalAuthContext = createAuthContext(authContext)
}

const requireAuthContext = () =>
  requireNotUndefinedOrNull(
    globalAuthContext,
    'Authentication context is missing. This may be because your user account is not linked to a user entity. Please try again or contact support for assistance if the problem persists.'
  )

type EntityRepositoryParams = {
  typeId?: EntityTypeId
  searchQuery?: string
  types: ReadonlyMap<EntityTypeId, EntityType>
  pageSize: number
  requestFetchEntity?: (id: EntityId) => Promise<EntityWithLabelAndRelated | null>
  requestFetchEntitiesBatch?: (
    ids: readonly EntityId[]
  ) => Promise<ReadonlyMap<EntityId, EntityWithLabelAndRelated | null>>
  requestFetchEntitiesPage?: (
    typeId: EntityTypeId,
    types: ReadonlyMap<EntityTypeId, EntityType>,
    searchQuery: string | undefined
  ) => (page: number, pageSize: number) => Promise<Page<EntityId, EntityWithLabelAndRelated>>
  requestCreateEntity?: (entity: EntityToCreate) => Promise<EntityId>
  requestCreateEntitiesBatch?: (entities: readonly EntityToCreate[]) => Promise<readonly EntityId[]>
  requestModifyEntity?: (id: EntityId, patch: Patch) => Promise<void>
  requestModifyEntities?: (patchesByEntityId: Map<EntityId, Patch>) => Promise<void>
  requestDeleteEntity?: (id: EntityId) => Promise<void>
}

// Limitations:
// We assume types don't change while this repository exists.
// If they do, we need to invalidate the cache / re-create the repository.
class EntityRepository extends PageCache<EntityId, EntityWithLabelAndRelated | null> {
  constructor({
    typeId,
    searchQuery,
    types,
    pageSize,
    requestFetchEntity,
    requestFetchEntitiesBatch,
    requestFetchEntitiesPage,
    requestCreateEntity,
    requestCreateEntitiesBatch,
    requestModifyEntity,
    requestModifyEntities,
    requestDeleteEntity,
  }: EntityRepositoryParams) {
    const batchCache = new BatchCache<EntityId, EntityWithLabelAndRelated | null>(
      requestFetchEntity || ((id) => EntityRepository.fetchEntityDefault(id, types)),
      requestFetchEntitiesBatch || ((ids) => EntityRepository.fetchEntitiesBatchDefault(ids, types))
    )
    super(
      batchCache,
      typeId !== undefined
        ? requestFetchEntitiesPage
          ? requestFetchEntitiesPage(typeId, types, searchQuery)
          : EntityRepository.fetchPage(typeId, types, searchQuery)
        : () => {
            throw new Error('Fetching entities by page is disabled, because typeId is not set')
          },
      pageSize
    )
    this.batchCache = batchCache
    this.doCreateEntity = requestCreateEntity || EntityRepository.createEntityDefault
    this.doCreateEntitiesBatch = requestCreateEntitiesBatch || EntityRepository.createEntitiesBatchDefault
    this.doModifyEntity = requestModifyEntity || EntityRepository.modifyEntityDefault
    this.doModifyEntities = requestModifyEntities || EntityRepository.modifyEntitiesDefault
    this.doDeleteEntity = requestDeleteEntity || EntityRepository.deleteEntityDefault
  }

  private readonly batchCache: BatchCache<EntityId, EntityWithLabelAndRelated | null>

  private readonly doCreateEntity: (entity: EntityToCreate) => Promise<EntityId>

  private readonly doCreateEntitiesBatch: (entities: readonly EntityToCreate[]) => Promise<readonly EntityId[]>

  private readonly doModifyEntity: (id: EntityId, patch: Patch) => Promise<void>

  private readonly doModifyEntities: (patchesByEntityId: Map<EntityId, Patch>) => Promise<void>

  private readonly doDeleteEntity: (id: EntityId) => Promise<void>

  private getRelatedEntityIds = (ids: readonly EntityId[]): EntityId[] =>
    Array.from(this.batchCache.peekAll())
      .filter(([, entity]) => entity != null && ids.some((it) => entity.isRelated(it)))
      .map(([it]) => it)

  private async refreshIncludingRelated(ids: readonly EntityId[]) {
    await this.batchCache.refreshBatch([...ids, ...this.getRelatedEntityIds(ids)])
  }

  private invalidateIncludingRelated(ids: readonly EntityId[]) {
    this.batchCache.invalidateBatch([...ids, ...this.getRelatedEntityIds(ids)])
  }

  async createEntity(entity: EntityToCreate): Promise<EntityId> {
    const id = await this.doCreateEntity(entity)
    await this.refreshIncludingRelated([id])
    return id
  }

  async createEntities(entities: readonly EntityToCreate[]): Promise<readonly EntityId[]> {
    const ids = await this.doCreateEntitiesBatch(entities)
    await this.refreshIncludingRelated(ids)
    return ids
  }

  async modifyEntity(id: EntityId, patch: Patch): Promise<EntityId> {
    await this.doModifyEntity(id, patch)
    await this.refreshIncludingRelated([id])
    return id
  }

  async modifyEntities(patchesByEntityId: Map<EntityId, Patch>): Promise<readonly EntityId[]> {
    await this.doModifyEntities(patchesByEntityId)
    const ids = Array.from(patchesByEntityId.keys())
    await this.refreshIncludingRelated(ids)
    return ids
  }

  async deleteEntity(id: EntityId) {
    await this.doDeleteEntity(id)
    this.invalidateIncludingRelated([id])
    return id
  }

  async getEntityTypeIds(ids: readonly EntityId[]): Promise<ReadonlyMap<EntityId, EntityTypeId>> {
    const entities = await this.batchCache.getBatch(ids)

    return new EqualityHashMap(
      Array.from(entities.entries())
        .filter(([_, value]) => value !== null)
        .map(([key, value]): [EntityId, EntityTypeId] => [key, value!!.entity.typeId])
    )
  }

  private static fetchEntityDefault = (id: EntityId, types: ReadonlyMap<EntityTypeId, EntityType>) =>
    EntityRepository.fetchEntitiesBatchDefault([id], types).then((it) => it.get(id) ?? null)

  private static createEntityDefault = async (entity: EntityToCreate): Promise<EntityId> => {
    const ids = await postEntities({
      entities: [entity],
      authContext: requireAuthContext(),
    })
    return ids.ids.asJsReadonlyArrayView()[0]
  }

  private static createEntitiesBatchDefault = async (
    entities: readonly EntityToCreate[]
  ): Promise<readonly EntityId[]> => {
    const ids = await postEntities({
      entities,
      authContext: requireAuthContext(),
    })
    return ids.ids.asJsReadonlyArrayView()
  }

  private static modifyEntityDefault = async (id: EntityId, patch: Patch) => {
    await patchEntity({ id, patch, authContext: requireAuthContext() })
  }

  private static modifyEntitiesDefault = async (patchesByEntityId: Map<EntityId, Patch>) => {
    await patchEntities({ patchesByEntityId, authContext: requireAuthContext() })
  }

  private static deleteEntityDefault = async (id: EntityId) => {
    await deleteEntity({ id, authContext: requireAuthContext() })
  }

  private static async fetchEntitiesBatchDefault(
    ids: readonly EntityId[],
    types: ReadonlyMap<EntityTypeId, EntityType>
  ): Promise<ReadonlyMap<EntityId, EntityWithLabelAndRelated | null>> {
    if (ids.length === 0) throw Error('ids must not be empty')

    const batches = chunked(ids, BATCH_SIZE)
    const responses = await Promise.all(
      batches.map((batch) =>
        getEntitiesBatch({
          ids: batch,
          withRelatedEntities: true,
          pageIndex: 0,
          pageSize: batch.length,
          authContext: requireAuthContext(),
        })
      )
    )

    return getEntitiesFromResponses(responses, types)
  }

  private static fetchPage =
    (typeId: EntityTypeId, types: ReadonlyMap<EntityTypeId, EntityType>, searchQuery: string | undefined) =>
    (page: number, pageSize: number): Promise<Page<EntityId, EntityWithLabelAndRelated>> => {
      if (!searchQuery || isEmpty(searchQuery))
        return getEntitiesOfType({
          id: typeId,
          withRelatedEntities: true,
          pageIndex: page,
          pageSize,
          authContext: requireAuthContext(),
        }).then((response) => ({
          entries: new EqualityHashMap(getEntitiesFromResponse(response, types)),
          totalCount: response.totalCount,
        }))
      else
        return searchEntitiesOfType({
          ids: [typeId],
          withRelatedEntities: true,
          searchQuery,
          pageIndex: page,
          pageSize,
          authContext: requireAuthContext(),
        }).then((response) => ({
          entries: new EqualityHashMap(getEntitiesFromResponse(response, types)),
          totalCount: response.totalCount,
        }))
    }
}

export default EntityRepository
