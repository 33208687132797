import { useContext, useEffect, useState } from 'react'
import { com } from '@eidu/entity'
import { Button, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import EntityForm from '../../components/entity/EntityForm'
import LoadingOverlay from '../../components/LoadingOverlay'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import DraftFieldValue, { fieldValueToDraftFieldValue } from '../../domain/entity/DraftFieldValue'
import EqualityHashMap from '../../util/EqualityHashMap'
import { requireNotUndefinedOrNull } from '../../util/require'
import useEntityRepository from '../../io/useEntityRepository'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import Entity = com.eidu.sharedlib.entity.Entity
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityType = com.eidu.sharedlib.entity.type.EntityType

const getNonNullFieldsFromEntity = (entity: Entity): ReadonlyMap<FieldId, DraftFieldValue> =>
  new EqualityHashMap<FieldId, DraftFieldValue>(
    Array.from(entity.valuesByFieldId.asJsReadonlyMapView())
      .filter((it): it is [FieldId, FieldValue] => it[1] !== null)
      .map(([id, value]) => [id, fieldValueToDraftFieldValue(id, value)])
  )

export type ReadyViewPageProps = {
  entityId: EntityId
  entityType: EntityType
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const ReadyViewPage = ({ entityId, entityType, entityTypes }: ReadyViewPageProps) => {
  const entityRepository = useEntityRepository(
    {
      typeId: entityType.id,
      types: entityTypes,
    },
    [entityType.id.asString(), entityTypes.size]
  )

  // Important: entityId must not change between undefined and
  // not undefined, otherwise React's hook order will be messed up.
  const entity = entityRepository.useItem(entityId)

  const navigate = useNavigate()

  // Important: all use* need to run, so don't replace this by anything using short-circuiting!
  const error = [entityRepository.useError(), entity === null ? 'Entity not found' : undefined]
    .find((it) => !!it)
    ?.let((it) => String(it))
  const loading = [entityRepository.useLoading(), entity === undefined].some((it) => it)

  const [fields, setFields] = useState<ReadonlyMap<FieldId, DraftFieldValue>>(new Map())

  useEffect(() => {
    if (entity) setFields(getNonNullFieldsFromEntity(entity.entity))
  }, [entity])

  const referenceableEntityTypeIds: EntityTypeId[] = entityType
    ? [
        ...new Set(
          Array.from(entityType.fields.asJsReadonlyArrayView()).flatMap((field) =>
            Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])
          )
        ),
      ]
    : []
  const referenceableEntitiesByTypeId = useEntitiesOfType(
    {
      typeIds: referenceableEntityTypeIds,
      allTypes: entityTypes,
    },
    []
  )

  return (
    <Stack padding={3} spacing={3}>
      {entityType && referenceableEntitiesByTypeId && (
        <>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h4">{entityType.name}</Typography>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'right' }}>
              {entity && (
                <Button onClick={() => navigate(`/entities/${entityType.id.asString()}/edit/${entityId.asString()}`)}>
                  Edit
                </Button>
              )}
            </Stack>
          </Stack>
          <EntityForm fieldsById={fields} entityType={entityType} entityTypes={entityTypes} />
          <Stack direction="row" spacing={3} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button onClick={() => navigate(-1)}>Back</Button>
          </Stack>
        </>
      )}
      <LoadingOverlay isOpen={loading || !(error || entityType)} />
    </Stack>
  )
}

const ViewEntityPage = () => {
  const params = useParams()
  const entityId = entityIdFromString(requireNotUndefinedOrNull(params.entityId, 'Entity ID is required'))
  const entityTypeId = entityTypeIdFromString(requireNotUndefinedOrNull(params.entityTypeId))

  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)
  const error = entityTypeRepository.useError()?.let((it) => String(it))

  return (
    <>
      {entityType && entityTypes && (
        <ReadyViewPage entityId={entityId} entityType={entityType} entityTypes={entityTypes} />
      )}
      <LoadingOverlay isOpen={!(error || entityType)} />
    </>
  )
}

export default ViewEntityPage
