import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { com } from '@eidu/entity'
import FieldValueEqualsInput, { SupportedEntitySelectorType } from './FieldValueEqualsInput'
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import NonEmptyList = com.quickbirdstudios.nonEmptyCollection.list.NonEmptyList
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId

type EntityTypeDropdownProps = {
  options: readonly EntityType[]
  entityTypeId: EntityTypeId | undefined
  setEntityTypeId: (entityTypeId: EntityTypeId | undefined) => void
}

const EntityTypeDropdown = ({ options, entityTypeId, setEntityTypeId }: EntityTypeDropdownProps) => (
  <FormControl>
    <InputLabel id="entity-type-label">Type</InputLabel>
    <Select
      labelId="entity-type-label"
      value={entityTypeId === undefined ? 'All' : entityTypeId.asString()}
      label="type"
      onChange={(event) => {
        setEntityTypeId(event.target.value === 'All' ? undefined : entityTypeIdFromString(event.target.value))
      }}
    >
      <MenuItem key="All" value="All">
        <em>All</em>
      </MenuItem>
      {options.map(({ id, name }) => (
        <MenuItem key={id.asString()} value={id.asString()}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

type EntitySelectorInputProps = {
  entityTypes: readonly EntityType[]
  entitySelector: EntitySelector
  setEntitySelector: (selector: EntitySelector) => void
}

const EntitySelectorInput: React.FC<EntitySelectorInputProps> = ({
  entityTypes,
  entitySelector,
  setEntitySelector,
}) => {
  const [entitySelectorType, setEntitySelectorType] = useState<SupportedEntitySelectorType>()
  const [entityTypeId, setEntityTypeId] = useState<EntityTypeId | undefined>()
  const [localFieldValueEquals, setLocalFieldValueEquals] = useState<EntitySelector.FieldValueEquals>()

  useEffect(() => {
    if (entitySelector instanceof EntitySelector.And) {
      const selectors = entitySelector.selectors.asJsReadonlyArrayView()
      const ofTypeSelector = selectors.find((selector) => selector instanceof EntitySelector.OfType)
      const fieldValueEqualsSelector = selectors.find((selector) => selector instanceof EntitySelector.FieldValueEquals)
      if (ofTypeSelector && fieldValueEqualsSelector) {
        setEntitySelectorType(SupportedEntitySelectorType.FieldValueEquals)
        setEntityTypeId(ofTypeSelector.typeId)
        setLocalFieldValueEquals(fieldValueEqualsSelector)
      } else {
        throw new Error('Invalid user selector')
      }
    } else if (entitySelector instanceof EntitySelector.OfType) {
      setEntitySelectorType(SupportedEntitySelectorType.AllOfType)
      setEntityTypeId(entitySelector.typeId)
      setLocalFieldValueEquals(undefined)
    } else {
      setEntityTypeId(undefined)
      setEntitySelectorType(undefined)
      setLocalFieldValueEquals(undefined)
    }
  }, [entitySelector])

  return (
    <Stack padding={0} spacing={3}>
      {entityTypes && (
        <EntityTypeDropdown
          options={entityTypes}
          entityTypeId={entityTypeId}
          setEntityTypeId={(newEntityTypeId) => {
            if (newEntityTypeId === undefined) setEntitySelector(EntitySelector.All)
            else setEntitySelector(new EntitySelector.OfType(newEntityTypeId))
          }}
        />
      )}
      {entityTypeId && entitySelectorType !== undefined && (
        <FieldValueEqualsInput
          entityTypeId={entityTypeId}
          supportedEntitySelectorType={entitySelectorType}
          setSupportedEntitySelectorType={(newType) => {
            if (newType === SupportedEntitySelectorType.AllOfType)
              setEntitySelector(new EntitySelector.OfType(entityTypeId))
            else setEntitySelectorType(newType)
          }}
          fieldValueEqualsSelector={localFieldValueEquals}
          setFieldValueEqualsSelector={(newSelector) => {
            if (newSelector)
              setEntitySelector(
                new EntitySelector.And(NonEmptyList.fromArray([new EntitySelector.OfType(entityTypeId), newSelector]))
              )
          }}
        />
      )}
    </Stack>
  )
}

export default EntitySelectorInput
