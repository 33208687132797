import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { com } from '@eidu/entity'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { useSnackBar } from '../../components/SnackBarProvider'
import EntityForm from '../../components/entity/EntityForm'
import LoadingOverlay from '../../components/LoadingOverlay'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import DraftFieldValue, {
  draftFieldValuesToFieldValues,
  fieldValueToDraftFieldValue,
  fieldValueToString,
} from '../../domain/entity/DraftFieldValue'
import EqualityHashMap from '../../util/EqualityHashMap'
import { requireNotUndefinedOrNull } from '../../util/require'
import createEntityToCreate from './createEntityToCreate'
import hasFieldError from '../../domain/entity/hasFieldError'
import { logException } from '../../util/Logging'
import useEntityRepository from '../../io/useEntityRepository'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import inviteAuthUser, { InviteAuthUserResult } from '../../api/entity/user/inviteAuthUser'
import { createAuthContext } from '../../api/authorization/AuthenticationContext'
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import FieldId = com.eidu.sharedlib.entity.field.FieldId
import Entity = com.eidu.sharedlib.entity.Entity
import entityIdFromString = com.eidu.sharedlib.entity.entityIdFromString
import entityTypeIdFromString = com.eidu.sharedlib.entity.type.entityTypeIdFromString
import EntityId = com.eidu.sharedlib.entity.EntityId
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind
import usePermittedActions from '../../io/usePermittedActions'
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import PermittedGlobalAction = com.eidu.sharedlib.entity.permission.PermittedGlobalAction

const getNonNullFieldsFromEntity = (entity: Entity): ReadonlyMap<FieldId, DraftFieldValue> =>
  new EqualityHashMap<FieldId, DraftFieldValue>(
    Array.from(entity.valuesByFieldId.asJsReadonlyMapView())
      .filter((it): it is [FieldId, FieldValue] => it[1] !== null)
      .map(([id, value]) => [id, fieldValueToDraftFieldValue(id, value)])
  )

export type ReadyCreateOrEditPageProps = {
  entityId?: EntityId
  entityType: EntityType
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const ReadyCreateOrEditPage = ({ entityId, entityType, entityTypes }: ReadyCreateOrEditPageProps) => {
  const entityRepository = useEntityRepository(
    {
      typeId: entityType.id,
      types: entityTypes,
    },
    [entityType.id.asString(), entityTypes.size]
  )

  const authContext = createAuthContext(useContext<IAuthContext>(AuthContext))

  const requested = useRef([new PermittedGlobalAction(GlobalAction.ManageAuthentication)])
  const [permissions] = usePermittedActions(requested.current)

  const hasGlobalPermission = (action: GlobalAction) =>
    permissions?.some((permission) => permission.equals(new PermittedGlobalAction(action)))

  // Important: entityId must not change between undefined and
  // not undefined, otherwise React's hook order will be messed up.
  const entity = entityId !== undefined ? entityRepository.useItem(entityId) : undefined

  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()

  // Important: all use* need to run, so don't replace this by anything using short-circuiting!
  const error = [entityRepository.useError(), entity === null ? 'Entity not found' : undefined]
    .find((it) => !!it)
    ?.let((it) => String(it))
  const loading = [entityRepository.useLoading(), entityId !== undefined && entity === undefined].some((it) => it)

  const [submitting, setSubmitting] = useState(false)

  const [fields, setFields] = useState<ReadonlyMap<FieldId, DraftFieldValue>>(new Map())
  const updateField = (value: DraftFieldValue) => setFields((it) => new EqualityHashMap(it).set(value.fieldId, value))

  useEffect(() => {
    if (entity) setFields(getNonNullFieldsFromEntity(entity.entity))
  }, [entity])

  const referenceableEntityTypeIds: EntityTypeId[] = entityType
    ? [
        ...new Set(
          Array.from(entityType.fields.asJsReadonlyArrayView()).flatMap((field) =>
            Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])
          )
        ),
      ]
    : []
  const referenceableEntitiesByTypeId = useEntitiesOfType(
    {
      typeIds: referenceableEntityTypeIds,
      allTypes: entityTypes,
    },
    []
  )

  const valid = useMemo(() => {
    if (!entityType || !referenceableEntitiesByTypeId) return false
    return entityType.fields.asJsReadonlyArrayView().every((field) => {
      const fieldValue = fields.get(field.id) ?? undefined
      return !hasFieldError(field, fieldValue, referenceableEntitiesByTypeId)
    })
  }, [entityType, fields, referenceableEntitiesByTypeId])

  const submit = useCallback(async () => {
    setSubmitting(true)
    try {
      if (!entity) {
        const localEntityType = requireNotUndefinedOrNull(entityType)
        try {
          const fieldValues = [...Array.from(fields.values())]
          await entityRepository.createEntity(createEntityToCreate(entityType.id, fieldValues, []))
          showSnackBar(`Created ${localEntityType.name}`, 'success')
          navigate(-1)
        } catch (e) {
          logException(e)
          showSnackBar(`Failed to create ${localEntityType.name}`, 'error')
        }
      } else {
        const localEntityType = requireNotUndefinedOrNull(entityType)
        const fieldValues = [...Array.from(fields.values())].filter((field) => {
          const originalValue = entity.entity.valuesByFieldId.asJsReadonlyMapView().get(field.fieldId)
          return !originalValue || fieldValueToString(originalValue) !== field.value
        })
        try {
          await entityRepository.modifyEntity(entity.entity.id, {
            valuesByFieldId: draftFieldValuesToFieldValues(fieldValues),
            links: null,
          })
          showSnackBar(`${localEntityType.name} updated`, 'success')
          navigate(-1)
        } catch (e) {
          logException(e)
          showSnackBar(`Failed to update ${localEntityType.name}`, 'error')
        }
      }
    } finally {
      setSubmitting(false)
    }
  }, [entityRepository, navigate, entityType, entity, fields, showSnackBar])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const confirmDelete = async () => {
    setDeleteDialogOpen(false)
    const localEntityType = requireNotUndefinedOrNull(entityType)
    try {
      await entityRepository.deleteEntity(requireNotUndefinedOrNull(entityId))
      showSnackBar(`Deleted ${localEntityType.name}`, 'success')
      navigate(-1)
    } catch (e) {
      logException(e)
      showSnackBar(`Failed to delete ${localEntityType.name}`, 'error')
    }
  }

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [inviteEmailOrPhone, setInviteEmailOrPhone] = useState('')

  const confirmInvite = async () => {
    setSubmitting(true)
    try {
      const result = await inviteAuthUser({
        entityId: requireNotUndefinedOrNull(entityId),
        emailOrPhoneNumber: inviteEmailOrPhone,
        authContext: requireNotUndefinedOrNull(authContext),
      })
      if (result === InviteAuthUserResult.Success) {
        showSnackBar(`Invited user for ${entityType?.name}`, 'success')
        setInviteDialogOpen(false)
      } else if (result === InviteAuthUserResult.InvalidEmailOrPhoneNumber) {
        showSnackBar(`This email or phone number appears to be invalid.`, 'error')
      } else if (result === InviteAuthUserResult.UserUnavailable) {
        showSnackBar(`The specified email or phone number is already associated with a different user`, 'error')
      }
    } catch (e) {
      logException(e)
      showSnackBar(`Failed to invite user for ${entityType?.name}`, 'error')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <Stack padding={3} spacing={3}>
        {entityType && referenceableEntitiesByTypeId && (
          <>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h4">{entityType.name}</Typography>
              <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'right' }}>
                {entity && (
                  <>
                    {entityType.kind === EntityTypeKind.User &&
                      hasGlobalPermission(GlobalAction.ManageAuthentication) && (
                        <Button onClick={() => setInviteDialogOpen(true)}>Invite</Button>
                      )}
                    <Button color="error" onClick={() => setDeleteDialogOpen(true)}>
                      Delete
                    </Button>
                  </>
                )}
              </Stack>
            </Stack>
            <EntityForm
              fieldsById={fields}
              updateField={updateField}
              entityType={entityType}
              entityTypes={entityTypes}
            />
            <Stack direction="row" spacing={3} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button variant="contained" onClick={submit} disabled={!valid}>
                Save
              </Button>
            </Stack>
          </>
        )}
        <LoadingOverlay isOpen={submitting || loading || !(error || entityType)} />
      </Stack>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There may still be references to this {entityType?.name ?? 'entity'} in the system. Are you sure you want to
            delete this {entityType?.name ?? 'entity'}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={inviteDialogOpen} onClose={() => setInviteDialogOpen(false)}>
        <DialogTitle>Invite user</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email or phone number of the user you would like to invite.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="emailOrPhoneNumber"
            label="Email or phone number"
            fullWidth
            variant="standard"
            value={inviteEmailOrPhone}
            onChange={(e) => setInviteEmailOrPhone(e.target.value)}
            error={inviteEmailOrPhone.trim().length === 0}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmInvite} color="primary" disabled={inviteEmailOrPhone.trim().length === 0}>
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const CreateOrEditPage = () => {
  const params = useParams()
  const entityId = params.entityId?.let(entityIdFromString)
  const entityTypeId = entityTypeIdFromString(requireNotUndefinedOrNull(params.entityTypeId))

  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)
  const error = entityTypeRepository.useError()?.let((it) => String(it))

  return (
    <>
      {entityType && entityTypes && (
        <ReadyCreateOrEditPage entityId={entityId} entityType={entityType} entityTypes={entityTypes} />
      )}
      <LoadingOverlay isOpen={!(error || entityType)} />
    </>
  )
}

export default CreateOrEditPage
