import { useContext, useMemo, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { com } from '@eidu/entity'
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import { EntityFormField } from '../EntityForm'
import fieldIdFromString = com.eidu.sharedlib.entity.field.fieldIdFromString
import { fieldValueToDraftFieldValue, draftFieldValueToFieldValue } from '../../../domain/entity/DraftFieldValue'
import { EntityTypesContext } from '../../../io/context/EntityTypes'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import FieldValue = com.eidu.sharedlib.entity.field.FieldValue
import Field = com.eidu.sharedlib.entity.field.Field
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import FieldId = com.eidu.sharedlib.entity.field.FieldId

export enum SupportedEntitySelectorType {
  AllOfType,
  FieldValueEquals,
}

type FieldValueEqualsInputProps = {
  entityTypeId: EntityTypeId
  supportedEntitySelectorType: SupportedEntitySelectorType
  setSupportedEntitySelectorType: (type: SupportedEntitySelectorType) => void
  fieldValueEqualsSelector: EntitySelector.FieldValueEquals | undefined
  setFieldValueEqualsSelector: (selector: EntitySelector.FieldValueEquals) => void
}

type TypeControlProps = {
  type: SupportedEntitySelectorType
  setType: (type: SupportedEntitySelectorType) => void
}

type FieldsControlProps = {
  field: Field | undefined
  setField: (field: Field) => void
  entityType: com.eidu.sharedlib.entity.type.EntityType
  disabled: boolean
}

type ValueControlProps = {
  field: Field
  value: FieldValue | undefined
  setValue: (value: FieldValue) => void
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const TypeControl = ({ type, setType }: TypeControlProps) => (
  <FormControl sx={{ minWidth: 200 }}>
    <InputLabel id="selector-type">Selector</InputLabel>
    <Select
      labelId="selector-type-label"
      value={type}
      label="Selector"
      onChange={(event) => {
        setType(event.target.value as SupportedEntitySelectorType)
      }}
    >
      <MenuItem key="All" value={SupportedEntitySelectorType.AllOfType}>
        <em>All</em>
      </MenuItem>
      <MenuItem key="FieldValueEquals" value={SupportedEntitySelectorType.FieldValueEquals}>
        <em>FieldValueEquals</em>
      </MenuItem>
    </Select>
  </FormControl>
)

const FieldsControl = ({ field, setField, entityType, disabled }: FieldsControlProps) => (
  <FormControl sx={{ minWidth: 200 }} disabled={disabled}>
    <InputLabel id="selector-fields">Fields</InputLabel>
    <Select
      labelId="selector-fields-label"
      value={field?.id.asString() ?? ''}
      disabled={disabled}
      label="Fields"
      onChange={(event) => {
        const selectedField = entityType.fields
          .asJsReadonlyArrayView()
          .find(({ id }) => id.equals(fieldIdFromString(event.target.value)))
        if (selectedField) {
          setField(selectedField)
        }
      }}
    >
      {entityType.fields.asJsReadonlyArrayView().map((entityField) => (
        <MenuItem key={entityField.name} value={entityField.id.asString()}>
          {entityField.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

const ValueControl = ({ field, value, setValue, entityTypes }: ValueControlProps) => (
  <FormControl sx={{ minWidth: 200 }}>
    {field && (
      <EntityFormField
        key={`selector-value-${field.id.asString()}`}
        field={field}
        fieldValue={field && value ? fieldValueToDraftFieldValue(field.id, value) : undefined}
        entityTypes={entityTypes}
        updateField={(draftFieldValue) => {
          setValue(draftFieldValueToFieldValue(draftFieldValue))
        }}
      />
    )}
  </FormControl>
)

const FieldValueEqualsInput = ({
  entityTypeId,
  supportedEntitySelectorType,
  setSupportedEntitySelectorType,
  fieldValueEqualsSelector,
  setFieldValueEqualsSelector,
}: FieldValueEqualsInputProps) => {
  const entityTypeRepository = useContext(EntityTypesContext)
  const entityTypes = entityTypeRepository.useAll()
  const entityType = entityTypeRepository.useItem(entityTypeId)

  const [localFieldId, setLocalFieldId] = useState<FieldId | undefined>(fieldValueEqualsSelector?.fieldId)

  const field = useMemo(() => {
    if (entityType)
      if (localFieldId) return entityType.fields.asJsReadonlyArrayView().find(({ id }) => id.equals(localFieldId))
      else return entityType.fields.asJsReadonlyArrayView()[0]
    else return undefined
  }, [entityType, localFieldId])

  return (
    entityType &&
    entityTypes && (
      <Stack direction="row" spacing={2} sx={{ padding: 2, backgroundColor: '#f5f5f5', borderRadius: 1 }}>
        <TypeControl type={supportedEntitySelectorType} setType={setSupportedEntitySelectorType} />
        <FieldsControl
          field={field}
          entityType={entityType}
          disabled={supportedEntitySelectorType !== SupportedEntitySelectorType.FieldValueEquals}
          setField={(newField) => setLocalFieldId(newField.id)}
        />
        {supportedEntitySelectorType === SupportedEntitySelectorType.FieldValueEquals && field ? (
          <ValueControl
            entityTypes={entityTypes}
            field={field}
            value={fieldValueEqualsSelector?.value}
            setValue={(newValue) =>
              setFieldValueEqualsSelector(new EntitySelector.FieldValueEquals(field.id, newValue))
            }
          />
        ) : (
          <TextField disabled={supportedEntitySelectorType !== SupportedEntitySelectorType.FieldValueEquals} />
        )}
      </Stack>
    )
  )
}

export default FieldValueEqualsInput
