import { com } from '@eidu/entity'
import PermittedActions = com.eidu.sharedlib.entity.permission.PermittedActions
import serializeToJsonString = com.eidu.sharedlib.entity.util.serializeToJsonString
import apiBaseUrl from '../baseUrl'
import AuthenticationContext from '../../authorization/AuthenticationContext'

export type GetPermittedActionsParameters = {
  requested: PermittedActions
  authContext: AuthenticationContext
}

const getPermittedActions = ({ requested, authContext }: GetPermittedActionsParameters): Promise<PermittedActions> =>
  fetch(
    `${apiBaseUrl}/organizations/${authContext.organizationId}/permissions/user/${authContext.userEntityId}/get-permitted-actions`,
    {
      headers: { Authorization: `Bearer ${authContext.accessToken}` },
      body: serializeToJsonString(requested),
      method: 'POST',
    }
  ).then(async (response) => {
    if (response.status !== 200)
      throw Error(`Unexpected response code ${response.status} from getUserPermissions endpoint`)
    return PermittedActions.fromJsonString(await response.text())
  })

export default getPermittedActions
