import { Alert, Box, Button, Collapse, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useMemo, useState } from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { com } from '@eidu/entity'
import LoadingOverlay from '../../components/LoadingOverlay'
import DataGridWithPageSelection from '../../components/DataGridWithPageSelection'
import pageSizeOptions from '../../util/pagination/pageSizeOptions'
import usePermissionGrantRepository from '../../io/usePermissionGrantRepository'
import { EntityTypesContext } from '../../io/context/EntityTypes'
import EntityTypeRepository from '../../io/EntityTypeRepository'
import { fieldValueToString } from '../../domain/entity/DraftFieldValue'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import EntityWithLabelAndRelated from '../../domain/entity/EntityWithLabelAndRelated'
import EqualityHashMap from '../../util/EqualityHashMap'
import { logException } from '../../util/Logging'
import PermissionGrantRepository from '../../io/PermissionGrantRepository'
import PaginationModel from '../../util/pagination/PaginationModel'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import EntityId = com.eidu.sharedlib.entity.EntityId
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import ReferenceValue = com.eidu.sharedlib.entity.field.ReferenceValue
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import actionLabel from '../../domain/permission/actionLabel'

const columns: GridColDef[] = [
  { field: 'row', headerName: 'Row', maxWidth: 50 },
  { field: 'users', headerName: 'Users' },
  { field: 'actions', headerName: 'Actions' },
]

const flattenUserSelectors = (selectors: EntitySelector[]): EntitySelector.OfType[] => {
  const andSelectors = selectors.filter((it) => it instanceof EntitySelector.And) as EntitySelector.And[]
  const orSelectors = selectors.filter((it) => it instanceof EntitySelector.Or) as EntitySelector.Or[]
  return selectors
    .filter((it) => it instanceof EntitySelector.OfType)
    .concat(
      ...andSelectors.map((it) => flattenUserSelectors([...it.selectors.asJsReadonlyArrayView()])),
      ...orSelectors.map((it) => flattenUserSelectors([...it.selectors.asJsReadonlyArrayView()]))
    )
}

const userSelectorsEntityTypes = (
  selectors: EntitySelector.OfType[],
  entityTypeRepository: EntityTypeRepository
): ReadonlyMap<EntityTypeId, EntityType> => {
  const [entityTypes, setEntityTypes] = useState<EqualityHashMap<EntityTypeId, EntityType>>(new EqualityHashMap())

  useEffect(() => {
    const getEntityTypes = async () => {
      const types = await Promise.all(selectors.map((it) => entityTypeRepository.get(it.typeId)))
      setEntityTypes(new EqualityHashMap(types.reduce((map, type) => map.set(type.id, type), new Map())))
    }
    getEntityTypes().catch(logException)
  }, [selectors])

  return entityTypes
}

const referencableEntitiesByTypeId = (
  entityTypes: ReadonlyMap<EntityTypeId, EntityType> | undefined,
  allEntityTypes: ReadonlyMap<EntityTypeId, EntityType> | undefined
): ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>> => {
  const referencableEntityTypeIds = useMemo(
    () =>
      Array.from(entityTypes?.values() ?? [])
        .flatMap((type) => type.fields.asJsReadonlyArrayView())
        .flatMap((field) => Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])),
    [entityTypes]
  )

  return (
    useEntitiesOfType({ typeIds: referencableEntityTypeIds, allTypes: allEntityTypes ?? new Map() }, [
      referencableEntityTypeIds,
      allEntityTypes,
    ]) ?? new Map()
  )
}

const entitySelectorLabel = (
  selector: EntitySelector,
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>,
  entitiesByTypeId: ReadonlyMap<EntityTypeId, ReadonlyMap<EntityId, EntityWithLabelAndRelated>>
): string => {
  if (EntitySelector.All.equals(selector)) return 'All users'
  if (selector instanceof EntitySelector.OfType)
    return `Users of type ${entityTypes.get(selector.typeId)?.name ?? `unknown type (${selector.typeId})`}`
  if (selector instanceof EntitySelector.FieldValueEquals) {
    const types = [...entityTypes.values()]
    const entityType = types.find((t) => t.fields.asJsReadonlyArrayView().find((f) => f.id.equals(selector.fieldId)))
    if (!entityType) return `Field id = ${selector.fieldId}`

    const field = entityType.fields.asJsReadonlyArrayView().find(({ id }) => id.equals(selector.fieldId))
    if (!field) return `Field id = ${selector.fieldId}`

    const fieldName = field.name
    const referencableEntities = new EqualityHashMap<EntityId, EntityWithLabelAndRelated>(
      Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? []).flatMap((typeId) =>
        Array.from(entitiesByTypeId.get(typeId)?.entries() ?? [])
      )
    )
    const fieldValue =
      field.type === FieldType.Reference
        ? referencableEntities.get((selector.value as ReferenceValue).value)?.primaryLabelText
        : fieldValueToString(selector.value)
    const fieldValueString = fieldValue === undefined ? '(loading...)' : fieldValue

    return `${fieldName} = ${fieldValueString}`
  }
  if (selector instanceof EntitySelector.And)
    return [...selector.selectors.asJsReadonlyArrayView()]
      .map((it) => entitySelectorLabel(it, entityTypes, entitiesByTypeId))
      .join(' AND ')
  if (selector instanceof EntitySelector.Or)
    return [...selector.selectors.asJsReadonlyArrayView()]
      .map((it) => entitySelectorLabel(it, entityTypes, entitiesByTypeId))
      .join(' OR ')
  return selector.toString()
}

type PermissionGrantsListPageContentProps = {
  entityTypeRepository: EntityTypeRepository
  permissionGrantRepository: PermissionGrantRepository
  paginationModel: PaginationModel
  setPaginationModel: (paginationModel: PaginationModel) => void
  loading: boolean
  onNewClicked: () => void
  onPermissionClicked: (id: string) => void
}

const PermissionGrantsListPageContent = ({
  entityTypeRepository,
  permissionGrantRepository,
  paginationModel,
  setPaginationModel,
  loading,
  onNewClicked,
  onPermissionClicked,
}: PermissionGrantsListPageContentProps) => {
  const page = permissionGrantRepository.usePage(paginationModel.page, [permissionGrantRepository])
  const [rows, setRows] = useState<unknown[]>()

  const allEntityTypes = entityTypeRepository.useAll()
  const userSelectors = useMemo(
    () => flattenUserSelectors([...page.values()].map((it) => it.permissionGrant.userSelector)),
    [page]
  )
  const entityTypes = userSelectorsEntityTypes(userSelectors, entityTypeRepository)
  const entitiesByTypeId = referencableEntitiesByTypeId(entityTypes, allEntityTypes)

  useEffect(() => {
    Promise.all(
      [...page.values()].map(async (permissionGrantDocument, index) =>
        Object.fromEntries([
          ['id', permissionGrantDocument.grantId.asString()],
          ['row', paginationModel.page * paginationModel.pageSize + index + 1],
          [
            'users',
            entitySelectorLabel(permissionGrantDocument.permissionGrant.userSelector, entityTypes, entitiesByTypeId),
          ],
          [
            'actions',
            [...permissionGrantDocument.permissionGrant.permissions.asJsReadonlySetView()]
              .map((permission) => actionLabel(permission.action))
              .join(', '),
          ],
        ])
      )
    ).then(setRows)
  }, [page, paginationModel.page, paginationModel.pageSize, entityTypes, entitiesByTypeId])
  const rowCount = permissionGrantRepository.useNumItems() ?? 0
  const loadingPermissions = permissionGrantRepository.useLoading()
  return (
    <Stack padding={3}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography variant="h4">Access control</Typography>
        <Button variant="contained" onClick={onNewClicked}>
          Grant permission
        </Button>
      </Stack>
      {rows && (
        <Box sx={{ paddingY: 2 }}>
          <DataGridWithPageSelection
            rows={rows}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            rowCount={rowCount}
            loading={loading || loadingPermissions}
            onRowClick={(params) => {
              onPermissionClicked(`${params.id}`)
            }}
          />
        </Box>
      )}
    </Stack>
  )
}

type PermissionGrantsListPageProps = {
  entityTypeRepository: EntityTypeRepository
  permissionGrantRepository: PermissionGrantRepository
  paginationModel: PaginationModel
  setPaginationModel: (paginationModel: PaginationModel) => void
}

const PermissionGrantsListPage = ({
  entityTypeRepository,
  permissionGrantRepository,
  paginationModel,
  setPaginationModel,
}: PermissionGrantsListPageProps) => {
  const navigate = useNavigate()

  const error = entityTypeRepository.useError()
  const loading = entityTypeRepository.useLoading()

  return (
    <Stack>
      <Collapse in={!!error}>
        <Alert
          variant="filled"
          severity="error"
          sx={{ margin: 2 }}
          action={
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>
              Reload
            </Button>
          }
        >
          <Box>{String(error)}</Box>
          <Box>Please reload the page and try again.</Box>
        </Alert>
      </Collapse>
      {!loading && !error && (
        <PermissionGrantsListPageContent
          entityTypeRepository={entityTypeRepository}
          permissionGrantRepository={permissionGrantRepository}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          loading={loading}
          onNewClicked={() => navigate('new')}
          onPermissionClicked={navigate}
        />
      )}
      <LoadingOverlay isOpen={loading} />
    </Stack>
  )
}

const PermissionGrantsListPageRoute = () => {
  const entityTypeRepository = useContext(EntityTypesContext)
  const [paginationModel, setPaginationModel] = useState({ pageSize: pageSizeOptions.small, page: 0 })
  const permissionGrantRepository = usePermissionGrantRepository(paginationModel.pageSize, [paginationModel])
  return (
    <PermissionGrantsListPage
      entityTypeRepository={entityTypeRepository}
      permissionGrantRepository={permissionGrantRepository}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
    />
  )
}

export { PermissionGrantsListPage }
export default PermissionGrantsListPageRoute
