import { IconButton, ListItemText, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { com } from '@eidu/entity'
import { useEffect, useState } from 'react'
import actionLabel from '../../domain/permission/actionLabel'
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import Permission = com.eidu.sharedlib.entity.permission.Permission
import GlobalPermission = com.eidu.sharedlib.entity.permission.GlobalPermission
import ALL_ACTIONS = com.eidu.sharedlib.entity.permission.ALL_ACTIONS
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityPermission = com.eidu.sharedlib.entity.permission.EntityPermission
import Action = com.eidu.sharedlib.entity.permission.Action
import EntityAction = com.eidu.sharedlib.entity.permission.EntityAction
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntitySelectorInput from '../entity/selector/EntitySelectorInput'

export type SelectOptionInputProps<T> = {
  availableOptions: readonly { [label: string]: T }[]
  selected: T
  setSelected: (selected: T) => void
  disabled?: boolean
}

const getItemLabel = <T extends unknown>(item: { [label: string]: T }) => Object.keys(item)[0]
const getItemValue = <T extends unknown>(item: { [label: string]: T }) => item[Object.keys(item)[0]]

const SelectOptionInput = <T extends unknown>({
  availableOptions,
  selected,
  setSelected,
  disabled,
}: SelectOptionInputProps<T>) => (
  <Select
    value={availableOptions.findIndex((item) => getItemValue(item) === selected)}
    disabled={disabled}
    onChange={(event: SelectChangeEvent<number>) => {
      const newSelectedIndex = event.target.value as number
      setSelected(getItemValue(availableOptions[newSelectedIndex]))
    }}
  >
    {availableOptions.map((value, index) => (
      <MenuItem key={JSON.stringify(value)} value={index}>
        <ListItemText primary={getItemLabel(value)} />
      </MenuItem>
    ))}
  </Select>
)

export type PermissionInputProps = {
  entityTypes: readonly EntityType[]
  permission: Permission
  onAdd: () => void
  onChange: (updatedPermission: Permission) => void
  onDelete: () => void
}

const PermissionInput: React.FC<PermissionInputProps> = ({
  entityTypes,
  permission,
  onAdd,
  onChange,
  onDelete,
}: PermissionInputProps) => {
  const [action, setAction] = useState<Action>(permission.action)
  const [targets, setTargets] = useState<EntitySelector>(
    permission instanceof EntityPermission ? permission.targets : EntitySelector.All
  )

  useEffect(() => {
    if (action instanceof GlobalAction) onChange(new GlobalPermission(action))
    else if (action instanceof EntityAction) onChange(new EntityPermission(action, targets))
  }, [action, targets])

  return (
    <Stack>
      <Stack direction="row" spacing={1}>
        <SelectOptionInput
          availableOptions={Array.from(ALL_ACTIONS.asJsReadonlySetView()).map((actionValue) => ({
            [actionLabel(actionValue)]: actionValue,
          }))}
          selected={action}
          setSelected={setAction}
        />
        {action instanceof EntityAction && (
          <EntitySelectorInput entityTypes={entityTypes} entitySelector={targets} setEntitySelector={setTargets} />
        )}
        <IconButton onClick={onDelete}>
          <Delete color="error" />
        </IconButton>
        <IconButton onClick={onAdd}>
          <Add color="primary" />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export type PermissionsInputProps = {
  entityTypes: readonly EntityType[]
  selected: readonly Permission[]
  onSelectedChange: (permissions: readonly Permission[]) => void
}

const PermissionsInput = ({ entityTypes, selected, onSelectedChange }: PermissionsInputProps) => {
  const defaultPermission = new GlobalPermission(GlobalAction.values()[0])
  return (
    <Stack direction="column" alignItems="flex-start" spacing="16px">
      {selected.length === 0 && (
        <IconButton onClick={() => onSelectedChange(selected.toSpliced(0, 0, defaultPermission))}>
          <Add color="primary" />
        </IconButton>
      )}
      {[...selected].map((permission, index) => {
        const key = `${index}`
        return (
          <PermissionInput
            key={key}
            entityTypes={entityTypes}
            permission={permission}
            onAdd={() => onSelectedChange(selected.toSpliced(index + 1, 0, defaultPermission))}
            onChange={(newValue) => onSelectedChange(selected.toSpliced(index, 1, newValue))}
            onDelete={() => onSelectedChange(selected.toSpliced(index, 1))}
          />
        )
      })}
    </Stack>
  )
}

export default PermissionsInput
