import { Stack, Typography } from '@mui/material'
import { com } from '@eidu/entity'
import React from 'react'
import PermissionsInput from './PermissionsInput'
import EntitySelectorInput from '../entity/selector/EntitySelectorInput'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import Permission = com.eidu.sharedlib.entity.permission.Permission
import EntitySelector = com.eidu.sharedlib.entity.selector.EntitySelector
import EntityTypeKind = com.eidu.sharedlib.entity.type.EntityTypeKind

type PermissionGrantFormProps = {
  entityTypes: readonly EntityType[]
  userSelector: EntitySelector
  setUserSelector: (selector: EntitySelector) => void
  selectedPermissions: readonly Permission[]
  setSelectedPermissions: (permissions: readonly Permission[]) => void
}

const PermissionGrantForm = ({
  entityTypes,
  userSelector,
  setUserSelector,
  selectedPermissions,
  setSelectedPermissions,
}: PermissionGrantFormProps) => (
  <Stack padding={0} spacing={3}>
    <Typography variant="h5">Users</Typography>
    <EntitySelectorInput
      entityTypes={entityTypes.filter((it) => it.kind === EntityTypeKind.User)}
      entitySelector={userSelector}
      setEntitySelector={setUserSelector}
    />

    <Typography variant="h5">Actions</Typography>
    <PermissionsInput
      entityTypes={entityTypes}
      selected={selectedPermissions}
      onSelectedChange={setSelectedPermissions}
    />
  </Stack>
)

export default PermissionGrantForm
