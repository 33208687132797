import { com } from '@eidu/entity'
import { Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import hasFieldError from '../../domain/entity/hasFieldError'
import DraftFieldValue from '../../domain/entity/DraftFieldValue'
import EntityFormReferenceField from './EntityFormReferenceField'
import useDebounced from '../../ui/debounce/useDebounced'
import EqualityHashMap from '../../util/EqualityHashMap'
import useEntitiesOfType from '../../io/useEntitiesOfType'
import EntityType = com.eidu.sharedlib.entity.type.EntityType
import EntityTypeId = com.eidu.sharedlib.entity.type.EntityTypeId
import Field = com.eidu.sharedlib.entity.field.Field
import FieldType = com.eidu.sharedlib.entity.field.FieldType
import FieldId = com.eidu.sharedlib.entity.field.FieldId

type EntityFormFieldProps = {
  field: Field
  fieldValue: DraftFieldValue | undefined
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
  updateField?: (value: DraftFieldValue) => void
}

const EntityFormField = ({ field, fieldValue, entityTypes, updateField = undefined }: EntityFormFieldProps) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery] = useDebounced(searchQuery)

  const referenceableEntityTypeIds: EntityTypeId[] = Array.from(field.validReferenceTypes?.asJsReadonlySetView() ?? [])

  const referenceableEntityTypesById: Map<EntityTypeId, EntityType> = new EqualityHashMap(
    [...(entityTypes || [])].filter(([id]) => referenceableEntityTypeIds.some((it) => it.equals(id)))
  )
  const referenceableEntitiesByTypeId =
    useEntitiesOfType(
      {
        typeIds: referenceableEntityTypeIds,
        searchQuery: debouncedSearchQuery,
        allTypes: entityTypes,
      },
      [debouncedSearchQuery]
    ) || new Map()

  const error = hasFieldError(field, fieldValue, referenceableEntitiesByTypeId)

  const updateValue = (event: { target: { value: string } }) =>
    updateField && updateField({ fieldId: field.id, type: field.type, value: event.target.value })

  switch (field.type) {
    case FieldType.Text:
      return (
        <TextField
          key={`field-${field.id.asString()}`}
          required={field.required}
          disabled={!updateField}
          label={field.name}
          value={fieldValue?.value ?? ''}
          onChange={updateValue}
          error={error}
        />
      )
    case FieldType.Number:
      return (
        <>
          <TextField
            key={`field-${field.id.asString()}`}
            required={field.required}
            disabled={!updateField}
            label={field.name}
            value={fieldValue?.value ?? ''}
            onChange={updateValue}
            error={error}
          />
          {fieldValue?.value && Number.isNaN(parseFloat(fieldValue.value)) && (
            <Typography>Please enter a valid number</Typography>
          )}
        </>
      )
    case FieldType.Reference:
      return (
        <EntityFormReferenceField
          field={field}
          fieldValue={fieldValue}
          referenceableEntityTypesById={referenceableEntityTypesById}
          referenceableEntitiesByTypeId={referenceableEntitiesByTypeId}
          updateField={updateField}
          error={error}
          setSearchQuery={setSearchQuery}
        />
      )
    default:
      return (
        <Typography key={`field-${field.id.asString()}`}>
          Field &quot;{field.name}&quot; has unsupported type {field.type.name}
        </Typography>
      )
  }
}

export type EntityFormProps = {
  fieldsById: ReadonlyMap<FieldId, DraftFieldValue>
  updateField?: (value: DraftFieldValue) => void
  entityType: EntityType
  entityTypes: ReadonlyMap<EntityTypeId, EntityType>
}

const EntityForm = ({ fieldsById, updateField = undefined, entityType, entityTypes }: EntityFormProps) => (
  <Stack padding={0} spacing={3}>
    {entityType.fields.asJsReadonlyArrayView().map((field) => (
      <EntityFormField
        key={`entity-form-field-${field.id.asString()}`}
        field={field}
        fieldValue={fieldsById.get(field.id)}
        entityTypes={entityTypes}
        updateField={updateField}
      />
    ))}
  </Stack>
)

export default EntityForm
export { EntityFormField }
