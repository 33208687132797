import { com } from '@eidu/entity'
import GlobalAction = com.eidu.sharedlib.entity.permission.GlobalAction
import Action = com.eidu.sharedlib.entity.permission.Action
import EntityAction = com.eidu.sharedlib.entity.permission.EntityAction

const actionLabel = (action: Action): string => {
  switch (action) {
    case GlobalAction.ManageEntityTypes:
      return 'Manage entity types'
    case GlobalAction.ManageAuthentication:
      return 'Manage authentication'
    case GlobalAction.ManagePermissionGrants:
      return 'Manage permission grants'
    case GlobalAction.ManageForms:
      return 'Manage forms'
    case EntityAction.CreateEntity:
      return 'Create entity'
    case EntityAction.ReadEntity:
      return 'Read entity'
    case EntityAction.UpdateEntity:
      return 'Update entity'
    case EntityAction.DeleteEntity:
      return 'Delete entity'
    default:
      return action.toString()
  }
}

export default actionLabel
